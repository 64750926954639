<template>
  <v-card class="mt-10 mb-10">
    <v-card-title> דף תוכן חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" ي>
        <v-row class="basic mt-2">
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              v-model="item.name"
              @keydown="name_exist = false"
              :rules="[(v) => !!v || 'שדה חובה']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                שם דף
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              readonly
              outlined
              dense
              label="סטטוס"
              :value="item.status ? 'פעיל' : 'לא פעיל'"
            >
              <template slot="append">
                <v-switch
                  v-model="item.status"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <VueEditor v-model="item.desc" :editor-toolbar="customToolbar" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          @click="save()"
          :loading="save_loader"
          :disabled="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  name: "Form",
  data() {
    return {
      save_loader: false,
      name_exist: false,
      customToolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
      ],
      item: {
        id: null,
        name: "",
        status: true,
        desc: null,
      },
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
        console.log("yes");
      }
      this.$emit("close_form");
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "intro_pages",
              method: "POST",
            },
            data: this.item,
          })
          .then((res) => {
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            // let errors = err.response.data.data;
            // Object.keys(errors).forEach((e) => {
            //   errors[e].forEach((s) => {
            //     if (s.includes("already been taken")) {
            //       this[`${e}_exist`] = true;
            //     }
            //   });
            // });
          });
      }
    },
  },
};
</script>

<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
</style>
